<template>
    <div class="card-tail col-span-4">
        <div class="mb-12">
            <p class="border-b mb-4 pb-2">Klientinformation</p>
            <div class="px-4">
                <div class="flex">
                    <p class="w-32">Namn:</p>
                    <p>{{ invoiceData.clientName }}</p>
                </div>
                <div class="flex">
                    <p class="w-32">Nummer:</p>
                    <p>FE{{ invoiceData.clientNumber }}</p>
                </div>
            </div>
        </div>

        <div class="mb-12">
            <p class="border-b mb-4 pb-2">Kundinformation</p>
            <div class="px-4">
                <div class="flex">
                    <p class="w-32">Namn:</p>
                    <p>{{ invoiceData.customerName }}</p>
                </div>
                <div class="flex">
                    <p class="w-32">Ort:</p>
                    <p>{{ invoiceData.customerAddress }}</p>
                </div>
                <div class="flex">
                    <p class="w-32">Stad:</p>
                    <p>{{ invoiceData.customerCity }}, {{ invoiceData.customerPostalCode }}</p>
                </div>
                <div class="flex">
                    <p class="w-32">Land:</p>
                    <p>{{ invoiceData.country }}</p>
                </div>
            </div>
        </div>

        <div class="mb-6">
            <p class="border-b mb-4 pb-2">Fakturainformation</p>
            <div class="px-4">
                <div class="flex justify-between">
                    <p class="text-xl font-semibold">Att betala:</p>
                    <p class="text-xl font-semibold">{{ invoiceData.grossAmount | swedishNumberFormat }} {{ invoiceData.currencyCode }}</p>
                </div>
                <div class="flex justify-between">
                    <p>Fakturanummer:</p>
                    <p>{{ invoiceData.invoiceNumber }}</p>
                </div>
                <div class="flex justify-between">
                    <p>Fakturadatum:</p>
                    <p>{{ dateFormater(invoiceData.invoiceDate) }}</p>
                </div>
                <div class="flex justify-between">
                    <p>Betala senast:</p>
                    <p>{{ dateFormater(invoiceData.dueDate) }}</p>
                </div>
                <div class="flex justify-between">
                    <p>OCR:</p>
                    <p>{{ invoiceData.ocrNumber }}</p>
                </div>
            </div>
        </div>

        <div class="flex flex-col mb-4">
            <div class="px-4">
                <div class="flex justify-between">
                    <p>Total netto:</p>
                    <p>{{ invoiceData.netAmount | swedishNumberFormat }} {{ invoiceData.currencyCode }}</p>
                </div>
                <div class="flex justify-between">
                    <p>Moms:</p>
                    <p>{{ invoiceData.vatAmount | swedishNumberFormat }} {{ invoiceData.currencyCode }}</p>
                </div>
                <div class="flex justify-between">
                    <p>Avrundning:</p>
                    <p>{{ invoiceData.rounding | swedishNumberFormat }} {{ invoiceData.currencyCode }}</p>
                </div>
                <div class="flex justify-between">
                    <p class="font-semibold">Totalsumma:</p>
                    <p class="font-semibold">{{ invoiceData.grossAmount | swedishNumberFormat }} {{ invoiceData.currencyCode }}</p>
                </div>
            </div>
        </div>

        <div v-if="invoiceData.invoiceLines" class="mb-6">
            <div v-for="item in invoiceData.invoiceLines" :key="item.id" class="border rounded-xl p-4 mb-2">
                <div class="flex justify-between">
                    <p>{{ item.description }}</p>
                    <p class="font-semibold whitespace-nowrap">{{ item.totalNetAmount | swedishNumberFormat }} {{ invoiceData.currencyCode }}</p>
                </div>
                <div class="flex justify-between">
                    <p>{{ item.vatRate }}% moms</p>
                    <p>{{ item.quantity }} St x {{ item.unitNetPrice | swedishNumberFormat }} {{ invoiceData.currencyCode }}</p>
                </div>
            </div>
        </div>

        <div v-if="invoiceData.invoiceArticleLines" class="mb-6">
            <div v-for="item in invoiceData.invoiceArticleLines" :key="item.id" class="border rounded-xl p-4 mb-2">
                <div class="flex justify-between">
                    <p>{{ item.articleTitle }}</p>
                    <p class="font-semibold whitespace-nowrap">{{ item.totalNetAmount | swedishNumberFormat }} {{ invoiceData.currencyCode }}</p>
                </div>
                <div class="flex justify-between">
                    <p>{{ item.articleNumber }} - {{ item.vatRate }}% moms</p>
                    <p>{{ item.quantity }} St x {{ item.unitNetPrice | swedishNumberFormat }} {{ invoiceData.currencyCode }}</p>
                </div>
                <td>{{ item.description }}</td>
            </div>
        </div>
    </div>
</template>
<script>
import moment from "moment";

export default {
    props: {
        invoiceData: {
            type: Object,
            default: () => {},
        },
    },

    methods: {
        dateFormater(date) {
            return moment(date).format("YYYY-MM-DD");
        },
    },
};
</script>
